import { useEffect } from 'react';

import { ROUTES } from '../../resources/routes-constants';

function useLogoutLogic() {

    //redirect
    useEffect(() => {
        localStorage.clear();
        window.location.replace(ROUTES.ADMIN_LOGIN_ROUTE);
    }, []);


};

export default useLogoutLogic;