import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { ROUTES } from './resources/routes-constants';
import { API } from 'resources/api-constants';


import './styles/main.sass';

//Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
import DefaultLayout from './layout/DefaultLayout'
import AgentLayout from './layout/AgentLayout'
import OperatorLayout from 'layout/OperatorLayout';
import AdminLayout from 'layout/AdminLayout';
import ConnectLayout from 'layout/ConnectLayout';

// main public pages
import Login from './pages/Login'
import Register from './pages/Register'
import RegisterSuccess from 'pages/RegisterSuccess'
import ForgotPassword from './pages/ForgotPassword'
import ForgotPasswordSuccess from './pages/ForgotPasswordSuccess'
import NotFoundPage from './pages/NotFoundPage'
import NewPassword from './pages/NewPassword'
import NewPasswordSuccess from './pages/NewPasswordSuccess'
// end main public pages

// end api test

import AdminLogin from 'pages/admin/Login'
import AdminForgotPassword from 'pages/admin/ForgotPassword'

import OperatorLogin from 'pages/operators/Login'

import AgentLogin from 'pages/agents/Login';
import AgentForgotPassword from 'pages/agents/ForgotPassword';
import AgentForgotPasswordSuccess from 'pages/agents/ForgotPasswordSuccess';

const RootComponent: React.FC = () => {
    const userSession = localStorage.getItem('user-session');

    return (
        <Router>
            <Routes>

                {/* ADMIN */}
                <Route path={ROUTES.ADMIN_LOGIN_ROUTE} element={<AdminLogin />} />
                <Route path={ROUTES.ADMIN_FORGOTPASSWORD_ROUTE} element={<AdminForgotPassword />} />
                {/* END ADMIN */}

                {/* AGENT */}
                <Route path={ROUTES.AGENT_LOGIN_ROUTE} element={<AgentLogin />} />
                <Route path={ROUTES.AGENT_FORGOTPASSWORD_ROUTE} element={<AgentForgotPassword />} />
                <Route path={ROUTES.AGENT_FORGOTPASSWORD_SUCCESS_ROUTE} element={<AgentForgotPasswordSuccess />} />
                {/* END AGENT */}

                {/* OPERATOR */}
                <Route path={ROUTES.OPERATOR_LOGIN_ROUTE} element={<OperatorLogin />} />
                {/* END OPERATOR */}

                {/* THIRD PARTY */}
                {/*<Route path={ROUTES.API_RESPONSE_CONNECT} element={<Connect />} />*/}
                {/*<Route path={ROUTES.API_RESPONSE_CATEGORIES + '/:id'} element={<ApiCategoryList />} />*/}
                {/* END THIRD PARTY */}


                {/* PLAYER */}
                <Route path="/" element={<Login />} />
                <Route path={ROUTES.REGISTER_SUCCESS_ROUTE} element={<RegisterSuccess />} />
                <Route path={ROUTES.REGISTER_ROUTE}>
                    <Route index element={<Register />} />
                    <Route path=":id" element={<Register />} />
                </Route>
                <Route path={ROUTES.FORGOTPASSWORD_ROUTE} element={<ForgotPassword />} />
                <Route path={ROUTES.NEWPASSWORD_ROUTE + '/:id'} element={<NewPassword />} />
                <Route path={ROUTES.NEWPASSWORD_SUCCESS_ROUTE} element={<NewPasswordSuccess />} />
                <Route path={ROUTES.FORGOTPASSWORD_SUCCESS_ROUTE} element={<ForgotPasswordSuccess />} />

                <Route path='/404' element={<NotFoundPage />} />
                
                {(userSession === API.AGENT_CODE) // AGENT PROTECTED ROUTES
                    ?
                    < Route path="*" element={<AgentLayout />} />
                    : (userSession === API.OPERATOR_CODE)
                        ?
                        < Route path="*" element={<OperatorLayout />} />
                        : (userSession === API.ADMIN_CODE)
                            ?
                            < Route path="*" element={<AdminLayout />} />
                            : (userSession === API.CONNECT_CODE)
                                ?
                                < Route path="*" element={<ConnectLayout />} />
                                :
                                <Route path="*" element={<DefaultLayout />} />
                    }
                {/* END PLAYER */}

                {/* < Route path="*" element={<DefaultLayout />} /> */}

            </Routes>
        </Router >
    )
}

export default RootComponent