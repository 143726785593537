import React, { useState } from 'react'
import { useEffect } from 'react'

import { ROUTES } from '../../../resources/routes-constants'
import { API } from '../../../resources/api-constants';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHouse, faPlay, faCartShopping, faUpload, faMoneyBill, faCoins, faQuestion,
    faCircleExclamation, faPenToSquare, faList, faRightFromBracket, faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import BottomNavBar from './BottomNavBar';

import { clientAxios } from 'utility/api/members/axiosPlayer';
import { formatNumberToCurrency } from 'utility/functions';
import CustomModal from '../../../components/members/modals/CustomModal';

function TopNavBar() {
    const [totalCredits, setTotalCredits] = useState('');
    const [show, setShow] = useState(false);

    function init() {
        const userSession = localStorage.getItem('user-session');
        if (userSession !== API.PLAYER_CODE) {
            window.location.href = ROUTES.LOGIN_ROUTE;
        }

        const loggedInUser = localStorage.getItem('access-token');

        if (!loggedInUser) { // not logged in, redirect
            localStorage.clear();
            window.location.href = ROUTES.LOGIN_ROUTE;
        }
        
       //GET TOTAL CREDITS
       clientAxios.get(
            API.PLAYER_ACCOUNT_LINK,
            {}
        ).then((response) => {
            
            localStorage.setItem('total-credits', response.data.data.wallet.balance);
            localStorage.setItem('email-address', response.data.data.email);
            localStorage.setItem('name', response.data.data.name);
            localStorage.setItem('total-credits', response.data.data.wallet.balance);

            setTotalCredits(response.data.data.wallet.balance);

        }).catch((error) => {
            if ('INVALID_TOKEN' === error?.response?.data?.result) {
                localStorage.clear();
                setShow(true);
            }
        });

        //END GET TOTAL CREDITS
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            {/* DISPLAY IF SESSION EXPIRES */}
            <CustomModal show={show} transaction='session' />
            {/* END DISPLAY IF SESSION EXPIRES */}

            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="mb-3">
                    <Container fluid className="topNav-container">
                        <Container fluid>
                            <Row>
                                <Col md={1} className='d-none d-md-block '>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='' />
                                </Col>
                                <Col md={8} className='d-none d-md-block'
                                    style={{ lineHeight: '1' }}
                                >
                                    <Navbar.Brand href={ROUTES.ACCOUNT_ROUTE} className='m-0 p-0'>
                                        <Row>
                                            <Col xs={4}>
                                                <span id="fullName">
                                                    {(localStorage.getItem('name') === 'null' || null == localStorage.getItem('name')) ?
                                                        '' :
                                                        localStorage.getItem('name')
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} className="mt-0 pt-0">
                                                <small className='text-warning mt-0 pt-0'>
                                                    {localStorage.getItem('email-address')}
                                                </small>
                                            </Col>
                                        </Row>
                                    </Navbar.Brand>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className='text-end'>
                                        <div className='mt-3 d-none d-md-block'></div>
                                        <a href={ROUTES.DEPOSIT_ROUTE} className='text-decoration-none'>
                                            <FontAwesomeIcon
                                                className='me-1 text-warning' icon={faCoins} />
                                            Credits: <span id="totalCredits" className='text-warning h6'>{formatNumberToCurrency(localStorage.getItem('total-credits'))}</span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ maxWidth: '300px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='header-title p-0'>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Container className='text-center'>
                                    <div className='d-flex flex-column'>
                                        <div className='text-start mb-2'>
                                            <img src='../logo.gif' className='w-50' alt="logo"/>
                                        </div>
                                        <div className='text-start'
                                        // style={{width:'350px', overflow: 'scroll'}}
                                        style={{width:'350px'}}
                                        >
                                            {(localStorage.getItem('name') === 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                            <br />
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs={4} className="mt-0 pt-0 text-center w-100">

                                        </Col>
                                    </Row>
                                </Container>
                                <Nav className="justify-content-end flex-grow-1 pe-3 mt-5">
                                    <Nav.Link href={ROUTES.HOME_ROUTE} className='p-1'>
                                        <FontAwesomeIcon icon={faHouse} className='me-3' />Home
                                    </Nav.Link>
                                    <Nav.Link href={ROUTES.PLAY_ROUTE} className='p-1'>
                                        <FontAwesomeIcon icon={faPlay} className='me-3' /> Play
                                    </Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>WALLET</Navbar.Text>
                                    <Nav.Link href="#action2" className='p-1'></Nav.Link>
                                    <Nav.Link href={ROUTES.DEPOSIT_ROUTE} className='p-1'><FontAwesomeIcon icon={faCartShopping} className='me-3' />Buy Credits</Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAW_ROUTE} className='p-1'><FontAwesomeIcon icon={faUpload} className='me-3' />Withdraw</Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>REQUESTS</Navbar.Text>
                                    <Nav.Link href={ROUTES.DEPOSITS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faMoneyBill} className='me-3' />Cash Ins</Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAWALS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faCoins} className='me-3' />Withrawals</Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>HISTORY</Navbar.Text>
                                    <Nav.Link href={ROUTES.DEPOSIT_HISTORY_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Cash Ins</Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAWAL_HISTORY_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Withdrawals</Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>HELP</Navbar.Text>
                                    <Nav.Link href={ROUTES.TUTORIAL_ROUTE} className='p-1'><FontAwesomeIcon icon={faQuestion} className='me-3' />Tutorial</Nav.Link>
                                    <Nav.Link href={ROUTES.RULES_ROUTE} className='p-1'><FontAwesomeIcon icon={faCircleExclamation} className='me-3' />Rules</Nav.Link>
                                    <hr />
                                    <Navbar.Text className=''>ACCOUNT SETTINGS</Navbar.Text>

                                    <Nav.Link href={ROUTES.ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.PLAYER_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}

            <BottomNavBar />
        </div>

    );
}

export default TopNavBar;