import AppContent from './AppContent'
import TopNavBar from 'pages/partials/members/TopNavbar'

const DefaultLayout = () => {
    //let hostname = window.location.hostname; 
    //const link = document.createElement('link');
    //link.href = '/css-theme/bilibet.css';
    //link.rel = 'stylesheet';
    //link.type = 'text/css';
    
    //document.getElementsByTagName('head')[0].appendChild(link);

  return (
    <div>
      <TopNavBar />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
    </div>
  )
}

export default DefaultLayout
