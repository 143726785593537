import React, { useState } from 'react';

import { clientAxios } from 'utility/api/members/axiosPlayer';
import { API } from '../../resources/api-constants';

const useHomeLogic = () => {

    //GET CATEGORIES
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState(true);

    async function getPostData() {
        await clientAxios.get(
            API.PLAYER_CATEGORIES_LINK
        ).then((response) => {
            setPost(response.data);
            setError(false);

        }).catch((error) => {
            setError(true);
            // if ('ERR_BAD_RESPONSE' == error.code) {
            //     window.location.reload();
            // }
        });
        setLoadingStatus(false);
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData();
    }, []);

    //END GET CATEGORIES

    return {
        post,
        loadingStatus
    };
}

export default useHomeLogic;