import React, { useState } from 'react';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios, refreshToken } from 'utility/api/members/axiosPlayer';
import { formatDate, formatNumberToCurrency } from 'utility/functions';
import { API } from '../../resources/api-constants';
import { pusherInit } from '../../utility/pusher';

const usePlayLogic = () => {

    //GET MATCH DETAIL
    const [postTrends, setTrendsPost] = useState({ data: Array(2) });
    const [error, setError] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [loadingStatusTrends, setLoadingStatusTrends] = useState(true);

    const [matchId, setMatchId] = useState(0);
    const [matchDate, setMatchDate] = useState('');
    const [fightNumber, setFightNumber] = useState(0);
    const [betStatus, setBetStatus] = useState(5);
    const [matchResult, setMatchResult] = useState('');
    const [settleStatus, setSettleStatus] = useState('');
    const [stream, setStream] = useState('');
    const [team1, setTeam1] = useState('');
    const [team2, setTeam2] = useState('');
    const [ongoingBet, setOngoingBet] = useState('0');
    const [pusherEvent, setPusherEvent] = useState('');

    //GET MATCH DETAILS
    async function getPostData() {
        await clientAxios.get(
            API.PLAYER_MATCH_LINK + '/' + localStorage.getItem('current-game') + '/view-match',
            {
            }
        ).then((response) => {
            localStorage.setItem('current-match', response.data.data.id);

            setMatchId(response.data.data.id);
            setFightNumber(response.data.data.matchNo);
            setMatchDate(response.data.data.date);
            setBetStatus(response.data.data.betStatus);
            setMatchResult(response.data.data.winner);
            setStream(response.data.data.streamIFrame);
            setTeam1(response.data.data.teamOneName);
            setTeam2(response.data.data.teamTwoName);
            setSettleStatus(response.data.data.settleStatus);
            setPusherEvent(response.data.data.pusherEventKey);
            setError(false);

            //getTrendsPost(response.data.data.date); // get Trends
        }).catch((error) => {
            setError(true);
        }).finally(() => {

            // if (null === matchId || undefined === matchId) {
            //     getPostDataInterval(); // refresh post with interval
            // }
        });

        //setLoadingStatus(false);
    }
    //END GET MATCH DETAILS

    function getTrendsPost() {
        var currentDate = Date();
        clientAxios.get(
            API.PLAYER_BET_TRENDS_LINK + '/' + localStorage.getItem('current-game') + '/trends',
            {
                //gameID: localStorage.getItem('current-game'),
                //date: formatDate(currentMatchDate)
            }
        ).then((response) => {
            setTrendsPost(response.data);

            setError(false);
        }).catch((error) => {
            setError(true);
        }).finally(() => {
            setLoadingStatusTrends(false);
        });
    }
    //END GET TRENDS
    async function getOngoingBets() {
        await clientAxios.get(
            API.PLAYER_BET_HISTORY_LINK,
            {
            }
        ).then((response) => {
            if (response.data?.data?.length > 0) {
                for (let i = 0; i < response.data?.data?.length; i++) {
                    if (response.data.data[i].settleStatus === "Not Settled") {
                        setOngoingBet('1');
                        return;
                    }
                }
            }

        }).catch((error) => {
            //setError(true);
        });

        //setLoadingStatus(false);
    }

    //GET PLAYER BETS

    //END GET PLAYER BETS

    //BET TRIGGER
    const [loadChoices, setLoadChoices] = useState(true);
    function handleBetSubmit(reloadChoice: any) {
        setLoadChoices(reloadChoice);
    }
    //END BET TRIGGER


    const handleUpdateMatchDetails = (e: any) => {
        getPostData();
    };

    const [pusherChannel, setPusherChannel] = useState(Object);

    // CALL INITIAL LOAD
    React.useEffect(() => {
      

        Promise.all([getPostData(), getTrendsPost()]).then(results => {
            const gameSelected = localStorage.getItem('current-game');

            if (!gameSelected || gameSelected === '') {
                window.location.replace(ROUTES.HOME_ROUTE);
            } else {
                setLoadingStatus(false);
            }
        });

        //getPostData();
        //getOngoingBets();
        //getTrendsPost();

        const pusher = pusherInit();

        const channel1 = pusher.subscribe('ez-app');
        setPusherChannel(channel1);

        return (() => {
            pusher.unsubscribe('ez-app');
        })

        
    }, []);


    return {
        pusherChannel,
        matchId, fightNumber, betStatus, matchResult,
        team1, team2, stream, settleStatus, pusherEvent,
        ongoingBet,
        postTrends,
        handleBetSubmit, loadChoices,
        handleUpdateMatchDetails,
        loadingStatus, loadingStatusTrends,
        getOngoingBets
    };
}

export default usePlayLogic;