import React from 'react'
import { Route, Routes } from 'react-router-dom'

// routes config
// import routes from '../routes'
import { ROUTES } from 'resources/routes-constants'

// main public pages
import NotFoundPage from 'pages/connect/NotFoundPage'
// end main public pages


// member pages
import Connect from 'pages/connect/Connect'
import ApiCategoryList from 'pages/connect/ApiCategoryList'
import ApiGameList from 'pages/connect/ApiGameList'
import ApiPlay from 'pages/connect/ApiPlay'

// end member pages

const ConnectContent = () => {

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
        {/*<Route path={ROUTES.API_RESPONSE_CONNECT} element={<Connect />} />*/}
        {/*<Route path={ROUTES.API_RESPONSE_CATEGORIES + '/:id'} element={<ApiCategoryList />} />*/}
        {/*<Route path={ROUTES.API_RESPONSE_GAMES + '/:id'} element={<ApiGameList />} />*/}
        {/*<Route path={ROUTES.API_RESPONSE_PLAY} element={<ApiPlay />} />*/}
    </Routes>
  )
}

export default React.memo(ConnectContent)
