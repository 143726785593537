// import { generateId } from 'utility/functions';

// var adminCode = generateId(32);

export const API = {
    CMS_URL: process.env.REACT_APP_CMS_URL,
    PLAYER_URL: process.env.REACT_APP_PLAYER_URL,
    AGENT_URL: process.env.REACT_APP_AGENT_URL,

    ADMIN_CODE:    'AewEwVaAHWlk-da_dkSwoqwCc',
    OPERATOR_CODE: 'XN1023_dklaElpoL2-PcWM5Md',
    AGENT_CODE:    'QG92s_931nbnsjdPw1monOqQf',
    PLAYER_CODE: 'klsOqwelkj801LLasd-m_skk2',

    CONNECT_CODE: 'atv98J_ipj801LmOqpQ30Iu31',

    //admin
    ADMIN_LOGIN_LINK: '/auth/login',
    ADMIN_LOGOUT_LINK: '/',
    ADMIN_RESET_PASSWORD_LINK: '/admin/me',

    ADMIN_RESET_USER_PASSWORD_LINK: '/api/admin/player/updateBlocked',
    ADMIN_RESET_AGENT_PASSWORD_LINK: '/api/admin/agent/updateBlocked',

    ADMIN_CHANGE_USER_EMAIL_LINK: '/admin/players',


    ADMIN_VALID_TOKEN: '/api/auth/checkValidToken',
    ADMIN_REFRESH_TOKEN: '/api/auth/refreshToken',

    ADMIN_DASHBOARD_LINK: '/api/admin/dashboard',

    ADMIN_OPERATORS_LINK: '/admin/operators',
    ADMIN_OPERATORS_CREATE_LINK: '/admin/operators',
    ADMIN_OPERATORS_UPDATE_LINK: '/admin/operators',
    ADMIN_OPERATORS_DEACTIVATE_LINK: '/admin/operators',
    ADMIN_OPERATORS_ACTIVATE_LINK: '/admin/operators',

    ADMIN_AGENTS_LINK: '/admin/agents',
    ADMIN_AGENTS_CREATE_LINK: '/admin/agents',
    ADMIN_AGENTS_UPDATE_LINK: '/api/admin/agent/update',
    ADMIN_AGENTS_DEACTIVATE_LINK: '/admin/agents',
    ADMIN_AGENTS_ACTIVATE_LINK: '/admin/agents',

    ADMIN_PLAYERS_LINK: '/admin/players',
    ADMIN_PLAYERS_DEACTIVATE_LINK: '/admin/players',
    ADMIN_PLAYERS_ACTIVATE_LINK: '/admin/players',

    ADMIN_GCASH_LINK: '/admin/gcash-accounts',
    ADMIN_GCASH_CREATE_LINK: '/admin/gcash-accounts',
    ADMIN_GCASH_UPDATE_LINK: '/admin/gcash-accounts',
    ADMIN_GCASH_DEACTIVATE_LINK: '/admin/gcash-accounts',
    ADMIN_GCASH_ACTIVATE_LINK: '/admin/gcash-accounts',

    ADMIN_CATEGORIES_LINK: '/admin/games/categories',
    ADMIN_CATEGORIES_CREATE_LINK: '/admin/games/categories',
    ADMIN_CATEGORIES_UPDATE_LINK: '/admin/games/categories',
    ADMIN_CATEGORIES_DEACTIVATE_LINK: '/api/admin/gameCategory/deactivate',
    ADMIN_CATEGORIES_ACTIVATE_LINK: '/api/admin/gameCategory/activate',

    ADMIN_GAMES_LINK: '/admin/games/categories',

    ADMIN_CREDITS_HISTORY_LINK: '/admin/player-credit-history',
    ADMIN_CREDITS_HISTORY_VIEW_LINK: '/admin/view-credit-history',
    ADMIN_CREDITS_REQUEST_LINK: '/admin/player-credit-request',
    ADMIN_CREDITS_REQUEST_VIEW_LINK: '/admin/view-credit-request',
    ADMIN_CREDITS_ACTION_LINK: '/admin/approve-credit-request',
    ADMIN_CREDITS_ACTION_DISAPPROVE_LINK: '/admin/decline-credit-request',

    ADMIN_AGENT_CREDITS_HISTORY_LINK: '/admin/credit-history',
    ADMIN_AGENT_CREDITS_HISTORY_VIEW_LINK: '/admin/view-credit-history',
    ADMIN_AGENT_CREDITS_REQUEST_VIEW_LINK: '/admin/view-credit-request',
    ADMIN_AGENT_CREDITS_REQUEST_LINK: '/admin/credit-request',

    ADMIN_WITHDRAWAL_HISTORY_LINK: '/admin/player-withdrawal-history',
    ADMIN_WITHDRAWAL_REQUEST_LINK: '/admin/player-withdrawal-request',
    ADMIN_WITHDRAWAL_REQUEST_VIEW_LINK: '/admin/view-withdrawal-request',
    ADMIN_WITHDRAWAL_HISTORY_VIEW_LINK: '/admin/view-withdrawal-history',
    ADMIN_WITHDRAWAL_ACTION_LINK: '/admin/approve-withdrawal-request',
    ADMIN_WITHDRAWAL_ACTION_DISAPPROVE_LINK: '/admin/decline-withdrawal-request',

    ADMIN_AGENT_WITHDRAWAL_HISTORY_LINK: '/admin/withdrawal-history',
    ADMIN_AGENT_WITHDRAWAL_REQUEST_LINK: '/admin/withdrawal-request',
    ADMIN_AGENT_WITHDRAWAL_REQUEST_VIEW_LINK: '/admin/view-withdrawal-request',
    ADMIN_AGENT_WITHDRAWAL_HISTORY_VIEW_LINK: '/admin/view-withdrawal-history',

    ADMIN_DASHBOARD_STATISTICS_LINK: '/admin/dashboard-stats',
    ADMIN_DASHBOARD_CREDITS_LINK: '/admin/dashboard-credits',
    ADMIN_DASHBOARD_COMMISSIONS_LINK: '/admin/dashboard-commissions',
    //end admin

    //operator
    OPERATOR_LOGIN_LINK: '/auth/login',
    OPERATOR_LOGOUT_ROUTE: '/api/auth/logout',
    OPERATOR_RESET_PASSWORD_LINK: '/operator/me/update-password',

    OPERATOR_CATEGORIES_LINK: '/operator/games/categories',
    OPERATOR_GAMES_LINK: '/operator/games',
    OPERATOR_GAMES_CREATE_LINK: '/operator/games',
    OPERATOR_GAMES_UPDATE_LINK: '/operator/games',
    OPERATOR_GAMES_DEACTIVATE_LINK: '/api/operator/game/deactivate',
    OPERATOR_GAMES_ACTIVATE_LINK: '/api/operator/game/activate',

    OPERATOR_GAMES_MATCHES_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_CREATE_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_UPDATE_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_VIEW_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_RESULT_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_RESET_RESULT_LINK: '/api/operator/match/reset',
    OPERATOR_GAMES_MATCH_BET_UPDATE_LINK: '/operator/games',
    OPERATOR_GAMES_MATCH_CURRENT_LINK: '/api/operator/match/currentMatch',
    OPERATOR_GAMES_MATCH_CURRENT_SETTLE_LINK: '/operator/matches',
    OPERATOR_GAMES_MATCH_VIEW_BETS_LINK: '/operator/games',
    //end operator

    LOGIN_LINK: '/auth/login-platform',
    LOGIN_USERNAME_LINK: '/auth/login-platform',

    //api test
    API_CONNECT: '/auth/authorize',
    API_BUY: '/player/cashin-request-portal',
    API_WITHDRAW: '/player/withdraw-request-portal',


    //players
    PLAYER_REGISTER_LINK: '/player/register',
    PLAYER_FORGOT_PASSWORD_LINK: '/auth/forgot-password',
    PLAYER_RESET_PASSWORD_LINK: '/api/player/resetPassword',
    PLAYER_UPDATE_NAME_LINK: '/api/player/updateName',

    PLAYER_FORGOT_RESET_PASSWORD_LINK: '/auth/change-password',

    PLAYER_GCASH_LINK: '/player/agents/gcash-accounts',

    PLAYER_CATEGORIES_LINK: '/player/categories',
    PLAYER_GAMES_LINK: '/player/categories',
    PLAYER_MATCH_LINK: '/player/games',
    PLAYER_MATCH_STATUS_LINK: '/api/player/play/matchStatus',

    PLAYER_BET_LINK: '/player/matches',
    PLAYER_BET_CURRENT_LINK: '/player/matches',
    PLAYER_BET_HISTORY_LINK: '/player/choice-history',
    PLAYER_BET_TRENDS_LINK: '/player/games',
    PLAYER_BET_PLACED: '/player/bets',

    PLAYER_WITHDRAW_REQUEST_LINK: '/player/withdraw/request',
    PLAYER_WITHDRAW_LIST_LINK: '/player/withdrawal-requests',
    PLAYER_WITHDRAW_HISTORY_LIST_LINK: '/player/withdrawal-requests-history',
    PLAYER_CREDITS_REQUEST_LINK: '/player/cashin-requests',
    PLAYER_CREDITS_LIST_LINK: '/player/credit-requests',
    PLAYER_CREDITS_HISTORY_LIST_LINK: '/player/credit-requests-history',

    PLAYER_CREDITS_TOTAL_LINK: '/api/player/credits',
    PLAYER_ACCOUNT_LINK: '/player/me',

    PLAYER_LOGIN_LINK: '/api/auth/login',
    PLAYER_LOGOUT_LINK: '/api/auth/logout',

    PLAYER_VALID_TOKEN: '/api/auth/checkValidToken',
    PLAYER_REFRESH_TOKEN: '/api/auth/refreshToken',

    PLAYER_OTP_GENERATE_LINK: '/player/withdraw/request-otp',
    PLAYER_OTP_VERIFY_LINK: '/player/withdraw/verify-otp',
    //end players

    //agents
    AGENT_ACCOUNT_LINK: '/agent/me',

    AGENT_LOGIN_LINK: '/auth/login-platform',
    AGENT_LOGIN_USERNAME_LINK: '/api/auth/loginUsername',
    AGENT_LOGOUT_ROUTE: '/api/auth/logout',

    AGENT_FORGOT_PASSWORD_LINK: '/api/auth/forgotPassword',
    AGENT_RESET_PASSWORD_LINK: '/agent/me/update-password',
    AGENT_ACCOUNT_UPDATE_NAME_LINK: '/agent/me/update-name',

    AGENT_DASHBOARD_LINK: '/agent/top-downlines',
    AGENT_MTD_LINK: '/agent/commission-mtd',
    AGENT_TOP_DOWNLINES_LINK: '/agent/top-downlines',
    AGENT_INVITE_LINK: '/agent/request-invite-link',

    AGENT_GCASH_LINK: '/agent/gcash-accounts',
    AGENT_UPDATE_GCASH_LINK: '/agent/me/update-gcash',

    AGENT_CREDITS_REQUEST_LINK: '/agent/cashin-requests',
    AGENT_CREDITS_LIST_LINK: '/agent/wallet-transactions',

    AGENT_COMMISSION_CONVERT_LINK: '/agent/convert-commissions',
    AGENT_COMMISSION_TRANSACTION_LINK: '/agent/convert-commissions',
    AGENT_COMMISSION_STATISTICS_LINK: '/agent/commission-stats',
    AGENT_COMMISSION_CREDITS_TOTAL_LINK: '/agent/commission-stats',
    
    AGENT_CREDITS_CREDITS_TOTAL_LINK: '/api/agent/transaction/credits',

    AGENT_DOWNLINES_LINK: '/agent/downlines',
    AGENT_DOWNLINES_CREDIT_REQUEST_LINK: '/agent/credit-request',
    AGENT_DOWNLINES_CREDIT_REQUEST_VIEW_LINK: '/agent/view-credit-request',
    AGENT_DOWNLINES_CREDIT_HISTORY_LINK: '/agent/credit-history',
    AGENT_DOWNLINES_CREDIT_HISTORY_VIEW_LINK: '/agent/view-credit-history',

    AGENT_DOWNLINES_CREDIT_APPROVE_ACTION_LINK: '/agent/approve-credit-request',
    AGENT_DOWNLINES_CREDIT_DISAPPROVE_ACTION_LINK: '/agent/decline-credit-request',

    AGENT_DOWNLINE_PROMOTE_LINK: '/agent/downlines/promote',

    AGENT_VALID_TOKEN: '/api/auth/checkValidToken',
    AGENT_REFRESH_TOKEN: '/api/auth/refreshToken',

    AGENT_WITHDRAW_REQUEST_LINK: '/agent/withdraw/request',


    AGENT_OTP_GENERATE_LINK: '/agent/withdraw/request-otp',
    AGENT_OTP_VERIFY_LINK: '/agent/withdraw/verify-otp',
    //end agents

    //config
    NUM_PER_PAGE: 10,
    MAX_PAGE_DISPLAY: 7,

    REFRESH_TOKEN_BUFFER: 900000,

    MAX_FILE_SIZE: 4000000, //4mb

    APP_EMAIL_ADDRESS: process.env.REACT_APP_EMAIL_ADDRESS
}