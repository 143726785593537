import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import { ROUTES } from '../../resources/routes-constants';

import { Table } from 'react-bootstrap';
import { formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';


const Home: React.FC = () => {

    const [error, setError] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(true);

    //GET CATEGORIES
    const [categoryPost, setCategoryPost] = React.useState({ data: Array(2) });
    const [categoryTotal, setCategoryTotal] = React.useState(0);

    function getCategoriesData() {
        clientAxios.get(
            API.ADMIN_CATEGORIES_LINK, {
        }
        ).then((response) => {
            setCategoryPost(response.data);
            setCategoryTotal(response.data.count);
            setError(null);

        }).catch(setError)
    }

    //GET COMMISSIONS, STATISTICS, CREDITS
    const [statisticsPost, setStatisticsPost] = useState(Object);

    const [creditsUsersPost, setCreditsUsersPost] = useState(Object);
    const [creditsSAPost, setCreditsSAPost] = useState(Object);
    const [creditsMAPost, setCreditsMAPost] = useState(Object);

    const [commissionsDailyPost, setCommissionsDailyPost] = useState(Object);
    const [commissionsMonthlyPost, setCommissionsMonthlyPost] = useState(Object);
    const [commissionsYearlyPost, setCommissionsYearlyPost] = useState(Object);

    const [userCount, setUserCount] = useState(0);
    const [maCount, setMaCount] = useState(0);
    const [saCount, setSaCount] = useState(0);
    const [operatorCount, setOperatorCount] = useState(0);

    async function getUserData() {
        await clientAxios.get(
            API.ADMIN_DASHBOARD_STATISTICS_LINK, {
        }
        ).then((response) => {
            //setStatisticsPost(response.data.data.users);
            //if (response.data.data.users[0].user_count > 0) {
            //    setOperatorCount(response.data.data.users[0].user_count);
            //}
            //if (response.data.data.users[1].user_count > 0) {
            //    setMaCount(response.data.data.users[1].user_count);
            //}
            if (response.data.data.users.length > 0) {
                response.data.data.users.map(function (user: any) {
                    //(credit: any, count: any) => (
                    if ('Master Agent' === user.title) {
                        setMaCount(user.user_count);
                    } else if ('Sub Agent' === user.title) {
                        setSaCount(user.user_count);
                    } else if ('Operator' === user.title) {
                        setOperatorCount(user.user_count);
                    } else if ('Player' === user.title) {
                        setUserCount(user.user_count);
                    }
                });
            }

            setError(null);

        }).catch(setError)
    }
    async function getCredits() {
        await clientAxios.get(
            API.ADMIN_DASHBOARD_CREDITS_LINK, {
            params: {
                r: 'p' // player
            }
        }
        ).then((response) => {
            setCreditsUsersPost(response.data);

            setError(null);

        }).catch((error) => {
        });

        await clientAxios.get(
            API.ADMIN_DASHBOARD_CREDITS_LINK, {
            params: {
                r: 'sa' // sub agents
            }
        }
        ).then((response) => {
            setCreditsSAPost(response.data);

            setError(null);

        }).catch(setError)

        await clientAxios.get(
            API.ADMIN_DASHBOARD_CREDITS_LINK, {
            params: {
                r: 'ma' // master agents
            }
        }
        ).then((response) => {
            setCreditsMAPost(response.data);

            setError(null);

        }).catch(setError)
    }

    async function getCommissions() {
        await clientAxios.get(
            API.ADMIN_DASHBOARD_COMMISSIONS_LINK, {
            params: {
                t: 'today' // daily
            }
        }
        ).then((response) => {
            setCommissionsDailyPost(response.data);

            setError(null);

        }).catch(setError)

        await clientAxios.get(
            API.ADMIN_DASHBOARD_COMMISSIONS_LINK, {
            params: {
                t: 'monthly' // monthly
            }
        }
        ).then((response) => {
            setCommissionsMonthlyPost(response.data);

            setError(null);

        }).catch(setError)

        await clientAxios.get(
            API.ADMIN_DASHBOARD_COMMISSIONS_LINK, {
            params: {
                t: 'yearly' // yearly
            }
        }
        ).then((response) => {
            setCommissionsYearlyPost(response.data);

            setError(null);

        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        Promise.all([getCategoriesData(), getUserData(), getCredits(), getCommissions()]).then(results => {
            setLoadingStatus(false);
        });
    }, []);

    return (
        <>
            <Container>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row className='mb-5'>
                            <Col
                                md={{ span: 6 }}
                                style={{ minHeight: '230px' }}
                                className="text-center bg-dark pb-2 p-4">
                                <Row className='mb-4'>
                                    <Col className='pb-2 pt-2 bg-admin-header'><h5>CATEGORIES</h5></Col>
                                </Row>
                                <Row>
                                    {categoryPost && categoryPost.data.map((categoryObj: any, count: any) => (
                                        <Col key={count} md={{ span: 4 }} className="pb-2 m-auto">
                                            <a href={ROUTES.ADMIN_CATEGORIES_ROUTE + '/1'}>
                                                <Image rounded fluid
                                                    src={categoryObj.image ?
                                                        categoryObj.image
                                                        :
                                                        '/images/placeholder.png'
                                                    }
                                                />
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>

                            <Col
                                md={{ span: 6 }}
                                style={{ minHeight: '230px' }}
                                className="text-center bg-dark pb-2 p-4">
                                <Row className='mb-4'>
                                    <Col className='pb-2 pt-2 bg-admin-header'><h5>USERS STATISTICS</h5></Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 4 }} className="pb-2">
                                        <div className='h6'><a href={ROUTES.ADMIN_OPERATORS_ROUTE + '/1'}>Operators</a></div>
                                        <div className='display-6 mt-3'><span className=''>{operatorCount}</span></div>
                                    </Col>
                                    <Col md={{ span: 4 }} className="pb-2">
                                        <div className='h6'><a href={ROUTES.ADMIN_AGENTS_ROUTE + '/1'}>Agents</a></div>
                                        <div className="display-6 mt-3"><span className=''>{saCount + maCount}</span></div>
                                    </Col>
                                    <Col md={{ span: 4 }} className="pb-2">
                                        <div className='h6'><a href={ROUTES.ADMIN_PLAYERS_ROUTE + '/1'}>Players</a></div>
                                        <div className="display-6 mt-3"><span className=''>{userCount}</span></div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <Row>
                            <Col
                                md={{ span: 6 }}
                                className="text-center bg-dark pb-2 p-4">
                                <Row className='mb-4'>
                                    <Col className='pb-2 pt-2 bg-admin-header'><h5>CREDITS</h5></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table striped hover variant="dark">
                                            <thead className='bg-dark'>
                                                <tr className='h5'>
                                                    <th>Type</th>
                                                    <th>Credits</th>
                                                    <th>Commissions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bg-dark p-5 text-white small'>
                                                    <td>
                                                        Players
                                                    </td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(creditsUsersPost?.data?.credits?.credits)}
                                                    </td>
                                                    <td className='text-warning'>
                                                        N/A
                                                    </td>
                                                </tr>
                                                <tr className='bg-dark p-5 text-white small'>
                                                    <td>Master Agents</td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(creditsMAPost?.data?.credits?.credits)}
                                                    </td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(creditsMAPost?.data?.commissions?.commissions)}
                                                    </td>
                                                </tr>
                                                <tr className='bg-dark p-5 text-white small'>
                                                    <td>Sub Agents</td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(creditsSAPost?.data?.credits?.credits)}
                                                    </td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(creditsSAPost?.data?.commissions?.commissions)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot className='fw-bold'>
                                                <tr>
                                                    <td className='h5'>TOTAL</td>
                                                    <td className='text-warning h5'>{formatNumberToCurrency(Number(creditsUsersPost?.data?.credits?.credits) + Number(creditsSAPost?.data?.credits?.credits) + Number(creditsMAPost?.data?.credits?.credits))}</td>
                                                    <td className='text-warning h5'>{formatNumberToCurrency(Number(creditsSAPost?.data?.commissions?.commissions) + Number(creditsMAPost?.data?.commissions?.commissions))}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                md={{ span: 6 }}
                                className="text-center bg-dark pb-2 p-4">
                                <Row className='mb-4'>
                                    <Col className='pb-2 pt-2 bg-admin-header'><h5>COMMISSIONS</h5></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table striped hover variant="dark">
                                            <thead className='bg-dark'>
                                                <tr className='h5'>
                                                    <th>Type</th>
                                                    <th>Day</th>
                                                    <th>Month</th>
                                                    <th>Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='bg-dark p-5 text-white small' key={Date.now() + Math.random()}>
                                                    <td>Website</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsDailyPost?.data?.website)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsMonthlyPost?.data?.website)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsYearlyPost?.data?.website)}</td>
                                                </tr>
                                                <tr className='bg-dark p-5 text-white small' key={Date.now() + Math.random()}>
                                                    <td>Master Agents</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsDailyPost?.data?.masterAgents)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsMonthlyPost?.data?.masterAgents)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsYearlyPost?.data?.masterAgents)}</td>
                                                </tr>
                                                <tr className='bg-dark p-5 text-white small' key={Date.now() + Math.random()}>
                                                    <td>Sub Agents</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsDailyPost?.data?.subAgents)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsMonthlyPost?.data?.subAgents)}</td>
                                                    <td className='text-warning'>{formatNumberToCurrency(commissionsYearlyPost?.data?.subAgents)}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot className='fw-bold'>
                                                <tr>
                                                    <td className='h5'>TOTAL</td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(Number(commissionsDailyPost?.data?.website) + Number(commissionsDailyPost?.data?.masterAgents) + Number(commissionsDailyPost?.data?.subAgents))}
                                                    </td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(Number(commissionsMonthlyPost?.data?.website) + Number(commissionsMonthlyPost?.data?.masterAgents) + Number(commissionsMonthlyPost?.data?.subAgents))}
                                                    </td>
                                                    <td className='text-warning'>
                                                        {formatNumberToCurrency(Number(commissionsYearlyPost?.data?.website) + Number(commissionsYearlyPost?.data?.masterAgents) + Number(commissionsYearlyPost?.data?.subAgents))}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        </>
    );
}

export default Home;