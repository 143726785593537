import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from 'resources/routes-constants';
import { API } from 'resources/api-constants';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faRightFromBracket, faGamepad, faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import OperatorCustomModal from '../../../components/operator/modals/OperatorCustomModal';
import { clientAxios } from '../../../utility/api/operator/axiosOperator';
//import OperatorCustomModal from 'components/operator/modals/OperatorCustomModal';

function TopNavBarOperator() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const userSession = localStorage.getItem('user-session');
        if (userSession !== API.OPERATOR_CODE) {
            window.location.href = ROUTES.OPERATOR_LOGIN_ROUTE;
        }

        clientAxios.get(
            API.OPERATOR_CATEGORIES_LINK,
            {}
        ).then((response) => {
        }).catch((error) => {
            if ('INVALID_TOKEN' === error?.response?.data?.result) {
                localStorage.clear();
                setShow(true);
            }
        });
    }, []);

    return (
        <div>
            {/* DISPLAY IF SESSION EXPIRES */}
            <OperatorCustomModal show={show} transaction='session' />
            {/* END DISPLAY IF SESSION EXPIRES */}


            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="mb-5 bg-info">
                    <Container fluid className="topNav-container">

                        <Container fluid>
                            <Row>
                                <Col md={1}>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='' />
                                </Col>
                                <Col md={8} className='d-none d-md-block mt-2'>
                                    {/* <Navbar.Brand href={ROUTES.OPERATOR_ACCOUNT_ROUTE} className='m-0 p-0'> */}
                                    <Navbar.Brand className='m-0 p-0'>
                                        <Row>
                                            <Col sm={4} className='h5'>Welcome, {localStorage.getItem('name')}</Col>
                                        </Row>
                                    </Navbar.Brand>
                                </Col>
                            </Row>
                        </Container>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ maxWidth: '300px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Operator Portal
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    {/* <Nav.Link href={ROUTES.OPERATOR_HOME_ROUTE} className='p-1'>
                                        <FontAwesomeIcon icon={faHouse} className='me-3' />Dashboard
                                    </Nav.Link> */}

                                    <Navbar.Text className='mt-3'>MATCHES</Navbar.Text>
                                    <Nav.Link href={ROUTES.OPERATOR_CATEGORIES_ROUTE} className='p-1'><FontAwesomeIcon icon={faGamepad} className='me-3' />Categories</Nav.Link>


                                    {/* <Navbar.Text className='mt-3'>REPORTS</Navbar.Text>
                                    <Nav.Link href='#' className='p-1'><FontAwesomeIcon icon={faStickyNote} className='me-3' />Operator</Nav.Link>
                                    <Nav.Link href='#' className='p-1'><FontAwesomeIcon icon={faStickyNote} className='me-3' />Provider</Nav.Link>
                                    <Nav.Link href='#' className='p-1'><FontAwesomeIcon icon={faStickyNote} className='me-3' />Activity</Nav.Link>
                                    <Nav.Link href='#' className='p-1'><FontAwesomeIcon icon={faStickyNote} className='me-3' />Bet History</Nav.Link> */}

                                    <Navbar.Text className='mt-3'>ACCOUNT SETTINGS</Navbar.Text>
                                    <Nav.Link href={ROUTES.OPERATOR_ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.OPERATOR_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    );
}

export default TopNavBarOperator;