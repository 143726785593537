import { useEffect } from 'react';

import { ROUTES } from '../../resources/routes-constants';

function useAgentLogoutLogic() {

    //redirect
    useEffect(() => {
        localStorage.clear();
        window.location.replace(ROUTES.AGENT_LOGIN_ROUTE);
    }, []);

};

export default useAgentLogoutLogic;